import React from "react";
import MiniCoaster from "../components/mini_coaster/MiniCoaster";
import "./Home.css";
import  { RootState } from "../store/indexStore";
import { useSelector } from "react-redux";
import { Constants } from "../Constants";
import groupBy from "../components/admin/util/groupBy";

// put all content picture
const Home = () => {
  const default_content = useSelector((state: RootState) => state.eduContent);
  let grouped = groupBy(Constants.DEFAULT_EDU_CONTENT.filter(content => content.display), "eduheader");

  // console.log("this is filtered",Constants.DEFAULT_EDU_CONTENT.filter(content => content.display))
  if (default_content.length>1){
    grouped = groupBy(default_content.filter(content => content.display), "eduheader");
  }

  return (
    <div className="containerHome">
      {Object.keys(grouped).filter(x => grouped[x][0].head_title==="Referral to Hospitals").map((k) => {
        return (
          <div key={grouped[k][0].head_title}>
            <h1 className="line">{grouped[k][0].head_title} </h1>
            <div className="subcontainerHome">
              {grouped[k].map((e) => 
                  <MiniCoaster title={e.title} picURL={e.pic_url} pathVar={e.path_var} key={e.title}/>
              )}
            </div>
          </div>
        );
      })}
      {Object.keys(grouped).filter(x => grouped[x][0].head_title!=="Referral to Hospitals").map((k) => {
        return (
          <div key={grouped[k][0].head_title}>
            <h1 className="line">{grouped[k][0].head_title} </h1>
            <div className="subcontainerHome">
              {grouped[k].map((e) => 
                  <MiniCoaster title={e.title} picURL={e.pic_url} pathVar={e.path_var} key={e.title}/>
              )}
            </div>
          </div>
        );
      })}
      <div className="footer"><a href="https://www.freepik.com/">Icon by Freepilkk</a></div>
    </div>
  );
};

export default Home;
